@import '../sizing.scss';
@import '../variables.scss';

.c-link {
  color: var(--color-gray-650);
  cursor: pointer;
  text-decoration: underline;
  white-space: nowrap;

  &:hover,
  &:active {
    color: var(--color-primary);

    ion-icon {
      color: var(--color-primary);
    }
  }

  &--primary {
    color: var(--color-primary);

    &:hover,
    &:active {
      color: var(--color-primary-300);

      ion-icon {
        color: var(--color-primary-300);
      }
    }
  }

  &--clear {
    text-decoration: none;
  }

  ion-icon {
    margin-left: $size-xs;
    color: var(--color-gray-650);
  }
}
