.c-dev-strip {
  position: fixed;
  z-index: 1000;
  top: 45px;
  left: -95px;
  -webkit-transform: translate(35px) rotateZ(45deg);
  transform: translate(35px) rotateZ(-46deg);
  background: -webkit-gradient(linear, left top, left bottom, from(red), to(red));
  background: linear-gradient(to bottom, red, red);
  opacity: 0.8;
  -webkit-box-shadow: 0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%);
  box-shadow: 0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%);
  width: 250px;
  text-align: center;
  pointer-events: none;

  div {
    color: #fafafafa;
    margin: 8px 0 4px 0;
    font-size: 32px;
    font-weight: 800;
  }
}
