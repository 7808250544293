@import '../sizing.scss';

.c-card {
  background: var(--color-white);
  display: flex;
  padding: $size-base;
  border-radius: $size-tiny;
  border: solid 1px var(--color-gray-200);
  max-width: 20.5 * $size-base;
  min-height: 9.375 * $size-base;
  cursor: pointer;
  position: relative;

  &--disabled {
    opacity: 0.5;
  }

  &--package {
    min-height: 12.5 * $size-base;
    padding: $size-lg $size-lg $size-xs $size-lg;
  }

  &--date {
    padding: $size-xs;
  }

  &--date-lg {
    padding: $size-xxs $size-xl 0;
  }

  &--ordered {
    min-height: 8.45 * $size-base;
    padding: $size-lg;
  }

  &--empty,
  &--locked {
    border: solid 1px var(--color-gray-500);
    background: var(--color-gray-100);
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
    max-width: 100%;
  }

  &__tag-text {
    position: absolute;
    top: $size-base;
    right: $size-base;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.33px;
    color: var(--color-green);
  }

  &__lock-wrapper {
    position: absolute;
    top: $size-base;
    right: $size-base;
  }

  &__lock-toggle {
    --handle-box-shadow: none;
    --handle-height: 1.25rem;
    --handle-width: 1.25rem;
    --track-background: var(--color-gray-200);
    --handle-background: var(--color-gray-100);
    &::part(handle) {
      background: url('../../assets/images/icons/lock-open.svg');
      background-repeat: no-repeat;
      background-size: 60%;
      background-color: var(--handle-background);
      background-position: center;
    }

    &::part(track) {
      border: solid 1px rgba(74, 144, 226, 0.25);
      height: 1.5 * $size-base;
      width: 2.75 * $size-base;
    }

    /* Checked State */
    --track-background-checked: var(--color-gray-200);
    --handle-background-checked: var(--color-gray-100);
    &.toggle-checked {
      &::part(handle) {
        background: url('../../assets/images/icons/lock-closed.svg');
        background-repeat: no-repeat;
        background-size: 60%;
        background-color: var(--handle-background-checked);
        background-position: center;
      }

      &::part(track) {
        border: solid 1px var(--color-gray-200);
      }
    }
  }

  &__icon {
    width: $size-lg;
    height: $size-lg;
    color: var(--color-white);
    border-radius: 50%;
    padding: $size-xxs;

    &-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 0;
      margin-right: $size-sm;
      margin-left: $size-3xs;
    }

    &-round {
      width: 3.125 * $size-base;
      height: 3.125 * $size-base;
    }
  }

  &__text-container {
    display: flex;
    flex-direction: column;

    &--date {
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    &--user {
      flex: 1;
      justify-content: center;
    }
  }

  &__title {
    font-size: $size-lg;
    margin-bottom: $size-xxs;
    word-break: break-all;
    font-weight: 400;

    &--user {
      font-size: 1.5 * $size-base;
      margin-bottom: 0;

      &--empty {
        color: var(--color-gray-700);
      }
    }

    &--ordered {
      font-size: 1.25 * $size-base;
      margin-bottom: 0;
    }

    &--locked {
      color: var(--color-gray-700);
    }
  }

  &__subtitle {
    color: var(--color-gray-600);
    font-size: 1.5 * $size-base;
    margin-top: $size-tiny;
    font-weight: 400;

    &--user {
      color: var(--color-gray-650);
      font-size: $size-sm;
      margin-top: $size-tiny;

      &--empty {
        color: var(--color-gray-675);
      }
    }

    &--ordered {
      color: var(--color-gray-700);
      font-size: $size-base;
    }

    &--locked {
      color: var(--color-gray-675);
    }
  }

  &__ordered-icon {
    --bg-color: var(--color-gray-200);

    height: 2.8 * $size-base;
    width: 2.8 * $size-base;
    min-height: 2.8 * $size-base;
    min-width: 2.8 * $size-base;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin-right: $size-sm;
    font-size: $size-sm;
    border-radius: 50%;
    background-color: var(--bg-color);

    &--selected {
      --bg-color: var(--color-primary);
    }

    &--indicator {
      font-size: $size-sm;
      color: var(--color-white);
      padding-top: 0.2 * $size-base;
      font-weight: 600;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
  }

  &__bottom-left {
    color: var(--color-gray-500);
    font-size: $size-sm;
  }

  &__bottom-right {
    color: var(--color-gray-500);
    margin-left: auto;
    font-size: $size-sm;
  }
}
