@import '../sizing.scss';
@import '../variables.scss';

.c-field {
  margin-bottom: $size-lg;
  position: relative;

  &--clean {
    height: unset;
    margin-bottom: -$size-sm;
    display: flex !important;
    align-items: center;

    .c-field__label {
      font-size: 0.875 * $size-base;
      font-weight: normal !important;
    }
  }

  .has-focus,
  &--focus {
    border-color: var(--color-primary) !important;
  }

  &--page-filter {
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }

  &__label {
    margin-bottom: $size-tiny;
    font-size: $size-base;
    font-weight: 500;
    color: var(--color-gray-700);

    &--invalid {
      color: var(--color-red);
    }

    &--ellipsis {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__input {
    &-uppercase .native-input:not(:placeholder-shown) {
      text-transform: uppercase !important;
    }

    &-wrapper {
      position: relative;
      display: flex;
      align-items: center;
    }
  }

  &__input,
  &__select,
  &__number {
    --highlight-color: transparent !important;
    --highlight-height: 0px;
    border: 2px solid var(--color-gray-400);
    border-radius: $size-tiny;
    padding: $size-tiny !important;
    height: 3.125 * $size-base;
    min-height: unset !important;

    .input-wrapper {
      height: 100% !important;
      overflow: hidden;
    }

    .input-wrapper .native-input.sc-ion-input-md {
      caret-color: var(--color-gray-700);
    }

    opacity: 1 !important;

    .input-highlight {
      display: none;
    }

    &:focus {
      --highlight-color: transparent;
      --highlight-height: 0px;

      border-color: var(--color-primary) !important;
    }

    &:disabled,
    &--disabled {
      background: var(--color-gray-100);
    }

    &--invalid,
    &.ion-touched.ion-invalid {
      border-color: var(--color-red);
    }

    &--has-validation-icon {
      padding-left: 3 * $size-base !important;
    }

    &--has-icon {
      padding-right: 3 * $size-base !important;
    }

    &--large {
      --placeholder-font-weight: 900;
      height: 4.875 * $size-base;
      border: none;
      border-bottom: 2px solid var(--color-gray-200);
      border-radius: 0;
      font-size: 2.625 * $size-base;
      text-align: center;
      width: 19.25 * $size-base;

      .native-input {
        font-size: 2.625 * $size-base;

        &::placeholder {
          font-size: 2.625 * $size-base;
          letter-spacing: 5px;
          text-align: center;
          color: var(--color-gray-600);
          text-transform: none;
        }
      }
    }
  }

  &__input {
    .input-wrapper {
      height: 100% !important;
      overflow: hidden;
      padding-left: 0.5 * $size-base;
    }

    &--textarea {
      min-height: 120px;
      padding-left: 0.75 * $size-base !important;
      overflow: auto;

      &::-webkit-scrollbar {
        display: none;
      }

      .textarea-wrapper .native-textarea {
        height: 100% !important;
        overflow: hidden;
      }
    }
  }

  &__select {
    padding: unset !important;

    &::after {
      content: '';
      right: 0.5 * $size-base;
      top: 50%;
      margin-top: -2px;
      position: absolute;
      width: 0px;
      height: 0px;
      border-top: 5px solid;
      border-right: 5px solid transparent;
      border-left: 5px solid transparent;
      color: var(--color-gray-700);
      pointer-events: none;
    }

    &::part(open) {
      .select-wrapper {
        height: 100% !important;
        overflow: hidden;
      }
    }

    &::part(text),
    &::part(placeholder) {
      padding: $size-xs $size-xxs;
      width: 110px;
    }

    &::part(icon) {
      opacity: 0;
      margin-right: $size-tiny;
    }

    &--clean {
      border: unset;
      padding: unset;
      padding-bottom: 0.5 * $size-base;
      margin-bottom: 6px;

      &::part(text) {
        color: var(--color-primary);
        font-size: 0.9 * $size-base;
      }
    }

    &--page-filter {
      background: var(--color-white);
      margin-left: $size-sm;
      width: 160px;

      &::part(text),
      &::part(placeholder) {
        color: var(--color-gray-800);
        font-size: 1.125 * $size-base;
        font-weight: 600;
      }
    }
  }

  &__toggle {
    border-radius: $size-tiny;
    width: 100%;
    max-width: 148px;
    height: 3 * $size-base;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: calc(0.85 * #{$size-base}) $size-base;
    cursor: pointer;
    user-select: none;
    text-transform: uppercase;
    font-weight: 600;

    &-container {
      background: none;
      color: inherit;
      border: none;
      padding: 0;
      font: inherit;
      cursor: pointer;
      border: 2px solid var(--color-gray-400);
      border-radius: $size-tiny;
      outline: 0;
      width: 100%;

      &:focus {
        border-color: var(--color-primary);
      }
    }
  }

  &__option {
    --border-color: var(--color-gray-400);
    border-bottom-color: var(--border-color);

    &-icon {
      margin-right: $size-md;
      color: inherit;
    }

    &:last-child {
      --border-color: transparent;
    }

    &--checked,
    &.item-radio-checked {
      color: var(--color-primary-500);
    }
  }

  &__otp {
    .otp-input {
      border: 0 !important;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    }
  }

  &__file {
    input {
      display: none;
    }

    label {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: $size-lg;
      border: 2px dashed var(--color-gray-400);
      border-radius: $size-sm;
      cursor: pointer;

      &:hover {
        border-color: var(--color-primary-500);
      }

      &.c-field__file--dnd {
        border-color: var(--color-primary-500);
        background: var(--color-blue-100);
      }

      p {
        margin: 0;
        color: var(--color-gray-600);
      }
    }

    &[disabled] {
      pointer-events: none;
      label {
        background: var(--color-gray-100);
      }
    }
  }

  &__checkbox {
    width: 100%;

    &:last-child {
      --border-color: transparent;
    }

    &-input {
      --size: 25px;
      --checkbox-background-checked: var(--color-gray-650);

      &::part(container) {
        border-radius: 0.25 * $size-base;
        border: 1px solid var(--color-gray-650);
        padding: $size-tiny;
      }
    }

    ion-checkbox {
      font-size: 1.125 * $size-base !important;
      line-height: 1.5 * $size-base !important;
      color: var(--color-gray-700) !important;
      white-space: normal !important;
      font-weight: normal;
    }
  }

  &__icon {
    position: absolute;
    z-index: 2;
    right: $size-md;
    color: var(--color-blue-700);
    font-size: 1.5 * $size-base;

    &--left {
      left: $size-md;
      right: auto;
    }
  }

  &__error {
    position: absolute;
    z-index: 2;
    display: inline-block;
    margin-top: 0.4 * $size-base;
    color: var(--color-red);
    font-size: 0.7 * $size-base;
    font-weight: 600;
    text-transform: uppercase;
  }

  &__number {
    background: unset;
    color: inherit;
    font-size: inherit;
    display: flex;
    align-items: center;
    position: relative;
    outline: 0;
    padding-right: 0 !important;
    width: 100%;

    &-input {
      all: unset;
      padding: calc($size-xxs + 2px) calc(0.5 * $size-base) $size-xxs;
      text-align: left;
      width: 100%;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    &-icon {
      color: var(--color-gray-700);
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: $size-xxl;
      position: relative;
      z-index: 2;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      width: $size-xxl;
      display: block;
      background: var(--color-blue-100);
      border-left: 1px solid var(--color-gray-400);
      border-radius: 0 #{0.25 * $size-base} #{0.25 * $size-base} 0;
    }
  }

  &__submit {
    --field-submit-height: 54px;

    font-weight: 500;
    font-size: $size-sm;
    position: relative;
    margin-bottom: $size-xxs;

    &-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      font-size: inherit;
      position: relative;
      margin-bottom: $size-xxl;
    }

    &-input {
      max-width: 100%;
      padding-left: $size-base !important;
      padding-right: $size-base !important;
      padding-top: $size-tiny !important;
      margin-bottom: 1.25rem;
      flex: unset;
      border: 1px solid var(--color-gray-400);
      border-radius: 3px;

      div {
        font-size: 1.25rem !important;
      }
    }

    &-btn {
      min-width: 200px;
      width: fit-content;
      height: var(--field-submit-height);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: inherit;
      text-transform: uppercase;
      margin-top: $size-md;

      color: var(--color-white);
      background: var(--color-primary);
      border-radius: $size-tiny;

      &:disabled {
        background: var(--color-gray-300);
      }

      span {
        font-size: inherit;
        color: inherit;
        display: block;
        padding-top: 2px;
        font-weight: 600;
      }
    }

    &-info {
      display: block;
      width: 100%;
      text-align: center;
      font-size: $size-sm;
      color: var(--color-gray-700);
      letter-spacing: 0.5px;
      line-height: 1.2;
      user-select: none;
      padding-bottom: $size-sm;
    }

    &-msg {
      font-size: $size-sm;
      text-align: center;
      display: block;
      margin-top: $size-sm;
      margin-bottom: $size-xxs;
    }
  }
}
