@import '../sizing.scss';
@import '../variables.scss';

.c-toast-container {
  position: fixed;
  top: $size-md;
  left: 0;
  z-index: 1000;

  display: flex;
  flex-direction: column;
  width: 100%;

  pointer-events: none;

  &__wrapper {
    display: flex;
    justify-content: center;
  }
}

.c-toast {
  @keyframes fadeInSlideIn {
    from {
      opacity: 0;
      transform: translateY(-100%);
    }
    to {
      opacity: 1;
      transform: none;
    }
  }

  @keyframes fadeOutSlideOut {
    from {
      opacity: 1;
      transform: none;
    }
    to {
      opacity: 0;
      transform: translateY(-100%);
    }
  }

  display: flex;
  align-items: center;
  max-width: 46 * $size-base;

  margin-bottom: $size-md;

  background: var(--color-white);
  border-radius: $size-xxs;
  box-shadow: 0 10px 30px 0 rgba(0, 30, 92, 0.12);
  color: var(--color-gray-900);
  font-weight: 500;
  position: relative;
  overflow: hidden;

  animation: fadeInSlideIn var(--toast-message-duration) forwards 1 cubic-bezier(0.075, 0.82, 0.165, 1);

  &--hidden {
    animation: fadeOutSlideOut var(--toast-message-duration) forwards 1 cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  &--success {
    --toast-background: var(--color-green);
  }

  &--info {
    --toast-background: var(--color-blue);
  }

  &--error {
    --toast-background: var(--color-red);
  }

  &--warning {
    --toast-background: var(--color-yellow);

    .c-toast__icon {
      color: var(--color-gray-900);
    }
  }

  &::before {
    position: absolute;
    content: '';
    display: block;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: var(--toast-background);
    width: 3.75 * $size-base;
    height: 100%;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    font-size: $size-md;
    width: 3.75 * $size-base;

    color: var(--color-white);

    ion-icon {
      color: inherit;
      display: flex;
      font-size: inherit;
    }
  }

  &__message,
  &__button {
    span {
      display: block;
      color: inherit;
      font-size: inherit;
      margin-bottom: -#{$size-tiny};
    }
  }

  &__message {
    padding: $size-sm;
  }

  &__label,
  &__button {
    font-size: 16px;
    line-height: 1.4;
    text-align: left;
  }

  &__button {
    all: unset;
    box-sizing: border-box;
    color: var(--color-blue-link);
    cursor: pointer;
    font-weight: inherit;
    padding: $size-sm;
    padding-left: 0;
    text-decoration: underline;
    pointer-events: initial;
  }
}
