@import '../sizing.scss';

.c-alert,
.select-alert {
  .alert-title {
    font-size: 1.375 * $size-base;
    color: var(--color-gray-700);
  }

  .alert-radio-label {
    text-align: center;
    font-size: $size-sm;
    --ion-color-primary: var(--color-primary);
    padding-top: $size-xs;
    padding-bottom: $size-xs;
  }

  .alert-radio-icon {
    display: none;
  }

  .alert-button-inner {
    color: var(--color-primary);
    text-transform: uppercase;
  }

  .sc-ion-alert-ios {
    border-right: unset;
    border-left: unset;
  }

  [aria-checked='true'] {
    --ion-color-primary: var(--color-primary);
  }

  &__button {
    &--delete {
      .alert-button-inner {
        color: var(--color-red);
      }
    }
  }
}
