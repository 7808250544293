@import 'sizing.scss';

@font-face {
  font-family: 'Overpass';
  src: url('/assets/fonts/Overpass-Regular.eot');
  src: url('/assets/fonts/Overpass-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Overpass';
  src: url('/assets/fonts/Overpass-Medium.woff2') format('woff2');
  src: url('/assets/fonts/Overpass-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Overpass';
  src: url('/assets/fonts/Overpass-SemiBold.eot');
  src: url('/assets/fonts/Overpass-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Overpass';
  src: url('/assets/fonts/Overpass-Bold.eot');
  src: url('/assets/fonts/Overpass-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Overpass';
  src: url('/assets/fonts/Overpass-Black.eot');
  src: url('/assets/fonts/Overpass-Black.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

* {
  font-family: 'Overpass', sans-serif;
  --ion-font-family: 'Overpass', sans-serif;

  font-weight: 400;
  line-height: 0.95;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  white-space: pre-line;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
small,
strong,
span,
div {
  color: var(--color-gray-900);
}

h1 {
  font-size: $size-base * 2.25;
  font-weight: 700;
}

h2 {
  font-size: $size-base * 2.125;
  font-weight: 400;
}

h3 {
  font-size: $size-base * 1.875;
  font-weight: 700;
}

h4 {
  font-size: $size-base * 1.5;
}

h5 {
  font-size: $size-base * 1.375;
}

h6 {
  font-size: $size-base * 1;
}

p,
span,
div {
  font-size: $size-base * 1;
}

small {
  font-size: $size-base * 0.875;
}
