@import '../sizing.scss';

.c-content-segment {
  grid-auto-columns: 1fr !important;

  &__item {
    font-size: 1.125 * $size-base;
    max-width: unset;
    text-transform: unset;
    transition: font-size 175ms ease;
    width: 100%;

    --background: var(--color-blue-100);
    --background-checked: var(--color-white);
    --background-focused: transparent;
    --background-hover: transparent;

    --color: var(--color-gray-700);
    --color-checked: var(--color-primary);
    --color-focused: var(--color-primary-300);
    --color-hover: var(--color-primary-300);

    --indicator-color: transparent;
    --ripple-color: transparent;

    &::part(native) {
      border: 0px solid var(--color-gray-200);
      border-bottom-width: 1px;
      border-right-width: 1px;
      padding: 0 6px;
      height: 70px;
    }

    &:last-of-type::part(native) {
      border-right-width: 0;
    }
  }

  .segment-button-checked {
    font-size: 1.375 * $size-base;

    &::part(native) {
      border-bottom-width: 0;
    }
  }

  span {
    display: block;
    font-size: inherit;
    color: inherit;
    padding-top: 4px;
    padding-bottom: 2px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
