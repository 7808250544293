@import 'sizing.scss';

.l-page {
  display: flex;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: var(--color-gray-100);

  &--loading {
    justify-content: center;
    position: absolute;
    inset: 0;
    z-index: 99;
  }
}

.l-container {
  margin: 0 auto;
  padding-top: $size-xxl;
  height: 100%;
  width: 100%;
  max-width: 69 * $size-base;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 1080px) {
    margin: 0;
  }

  @media (max-width: 512px) {
    margin: 0 $size-base;
  }

  @media (max-height: 780px) {
    padding-top: $size-sm;
  }

  &--top-lg {
    padding-top: 96px;
  }

  &--center {
    justify-content: center;
    align-items: center;
  }

  &__header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2 * $size-base;
  }
}

.l-grid {
  width: 100%;
  display: grid;

  &--cards {
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 1.625 * $size-base;
    row-gap: 1.625 * $size-base;

    @media screen and (max-width: 1023px) {
      grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 512px) {
      grid-template-columns: 1fr;
    }
  }

  &--panel {
    grid-template-columns: 18.75 * $size-base 1fr;
  }
}

.l-linelength {
  max-width: 64 * $size-base;

  &--tiny {
    max-width: 20 * $size-base;
  }

  &--xs {
    max-width: 26 * $size-base;
  }

  &--small {
    max-width: 32 * $size-base;
  }

  &--medium {
    max-width: 36 * $size-base;
  }

  &--large {
    max-width: 40 * $size-base;
  }

  &--xl {
    max-width: 52 * $size-base;
  }
}

.l-footer {
  margin-top: auto;
}
