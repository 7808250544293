@import '../sizing.scss';

.c-badge {
  display: inline-flex;
  padding-top: 4px;
  justify-content: center;
  align-items: center;
  width: 2.625 * $size-base;
  height: 2.625 * $size-base;

  border-radius: 50%;
  background-color: #e9e9e9;

  font-size: 1.5 * $size-base;
  font-weight: 700;
  text-transform: uppercase;

  &--lg {
    width: 3.125 * $size-base;
    height: 3.125 * $size-base;
  }

  &--empty {
    border: 2px solid var(--color-gray-300);
    background-color: var(--color-white);
  }

  &--active {
    background-color: var(--color-green);
    color: var(--color-white);

    ion-icon {
      color: var(--color-white);
    }
  }

  ion-icon {
    margin-top: -4px;
    font-size: 1.75 * $size-base;
  }
}
