$size-base: 1rem;
$size-tiny: 0.25 * $size-base; // 4px
$size-3xs: 0.5 * $size-base; // 8px
$size-xxs: 0.625 * $size-base; // 10px
$size-xs: 0.95 * $size-base; // 15px
$size-sm: 1.25 * $size-base; // 20px
$size-md: 1.625 * $size-base; // 26px
$size-lg: 1.875 * $size-base; // 30px
$size-xl: 2.1875 * $size-base; // 35px
$size-xxl: 2.5 * $size-base; // 40px
