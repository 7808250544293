@import '../sizing.scss';

ion-modal::part(content) {
  z-index: 101;
}

ion-modal::part(backdrop) {
  z-index: 100;
  --backdrop-opacity: 0.4;
}

.c-ion-modal {
  --border-radius: #{$size-xxs};
  overflow: hidden;

  &::part(content) {
    --width: auto;
    --height: auto;
  }

  .ion-page {
    position: relative;
    contain: content;
  }

  &--square {
    --border-radius: 0;
  }
}

.c-modal {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  background: white;
  margin-top: auto;
  margin-bottom: auto;
  padding: 50px;
  user-select: none;

  &__default-size {
    width: 780px;
    height: 560px;
  }

  &__close {
    position: absolute;
    top: 1.875 * $size-base;
    right: 1.875 * $size-base;
    width: 2.5 * $size-base;
    height: 2.5 * $size-base;
    display: grid;
    place-items: center;
    border-radius: 50%;
    background-color: #f5f5f5;
    cursor: pointer;

    ion-icon {
      font-size: 1.75 * $size-base;
      color: var(--color-gray-700);
    }
  }

  &__title {
    color: var(--color-gray-800);
    font-size: $size-xl;
    letter-spacing: 0.3px;
    text-align: center;
    font-weight: 400;
  }

  &__description {
    color: var(--color-gray-700);
    font-size: $size-sm;
    font-weight: 400;
    line-height: 1.5;
    margin: $size-sm 0;
    text-align: center;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: auto;
    gap: 50px;

    &--space-evenly {
      gap: 0;
      justify-content: space-evenly;
    }

    &--short {
      gap: 16px;
    }
  }
}
