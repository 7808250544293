@import '../sizing.scss';
@import '../variables.scss';

.c-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1.5em;
  letter-spacing: 1px;

  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  font-weight: 500;

  color: var(--button-color);
  background: transparent;
  font-size: $size-sm;
  border-radius: $size-tiny;

  width: 190px;
  height: 52px;

  &:disabled {
    pointer-events: none;
    opacity: 0.4;
  }

  &-icon {
    font-size: 1.5 * $size-base;
    margin-right: $size-xs;
  }

  &--lg {
    font-size: 1.125 * $size-base;
    width: 240px;
    height: 60px;
  }

  &--md {
    height: 2.375 * $size-base;
    width: 100%;

    .c-btn__text {
      margin-top: 0.2 * $size-base;
      font-size: 0.875 * $size-base !important;
    }
  }

  &--lg2 {
    font-size: 1.125 * $size-base;
    font-weight: 700;
    letter-spacing: normal;
    width: 250px;
    height: 3.125 * $size-base;
  }

  &--w-full {
    font-size: 1.125 * $size-base;
    height: 3 * $size-base;
    width: 100%;
  }

  &--size-fill {
    font-size: 1.125 * $size-base;
    height: 100%;
    width: 100%;
  }

  &--sm {
    min-width: 46px;
    width: auto;
    height: 48px;
  }

  &--fill {
    background: var(--button-color);
    color: var(--color-white);
  }

  &--outline {
    background: transparent;
    border: 2px solid var(--button-color);
  }

  &--primary {
    --button-color: var(--color-primary);

    &:active {
      --button-color: var(--color-primary-300);
    }
  }

  &--secondary {
    --button-color: var(--color-secondary-700);

    &:active {
      --button-color: var(--color-primary);
    }
  }

  &--danger {
    --button-color: var(--color-red);
  }

  &--inactive {
    --button-color: var(--color-gray-500);
    border: 2px solid var(--color-gray-400) !important;
  }

  &--neutral {
    --button-color: var(--color-blue-700);
  }

  &--gray {
    --button-color: var(--color-gray-600);
  }

  &--refresh {
    --color: #8091a5;

    all: unset;
    color: #8091a5;
    cursor: pointer;
    display: inline-flex;
    font-size: $size-lg;
  }

  span,
  span * {
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    display: block;
    padding-top: 2px;
  }
}
