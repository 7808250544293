@import '../sizing.scss';

.c-ion-loading {
  --spinner-color: var(--color-primary);

  .loading-wrapper {
    padding: $size-xs $size-md;
    border-radius: $size-xxs;
  }
}
