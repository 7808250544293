@import '../sizing.scss';

.c-header {
  border-bottom: 1px solid var(--color-gray-200);
  user-select: none;
  box-shadow: none;

  &::after {
    all: unset;
  }

  border-bottom: 1px solid var(--color-gray-200);
  user-select: none;

  &__container {
    display: flex;
    width: 100%;
    align-items: center;
    height: 100px;
  }

  &__start,
  &__content,
  &__end {
    height: 100%;
    flex-shrink: 0;
    border: 0 solid var(--color-gray-200);
  }

  &__start {
    border-right-width: 1px;
  }

  &__content {
    flex-grow: 1;
  }

  &__end {
    border-left-width: 1px;
  }

  &__back-btn {
    height: 100%;
    aspect-ratio: 1/1;
    --icon-font-size: 50px;
    --background-hover: unset;
    --background-focused: unset;
    --ripple-color: unset;

    &::part(icon) {
      color: var(--color-gray-300);
    }

    &:hover {
      &::part(icon) {
        color: var(--color-gray-200);
      }
    }

    &--mock {
      --background: unset;
      --background-hover: unset;
      --background-focused: unset;
      --box-shadow: unset;
      margin: 0;

      &-icon {
        width: 50px;
        height: 50px;

        background-image: url('/assets/icon/chevron-left-gray-300.svg');
        background-position: center;
        background-repeat: no-repeat;

        &:hover {
          background-image: url('/assets/icon/chevron-left-gray-200.svg');
        }
      }
    }
  }

  &__btn {
    display: flex;
    align-items: center;
    height: 100%;
    background: unset;

    font-weight: 500;
    font-size: $size-sm;
    letter-spacing: 0.5px;
    cursor: pointer;
    padding: 0 #{$size-base * 3};
    text-decoration: none;
    text-transform: uppercase;

    &:disabled {
      opacity: 0.4;
    }

    span {
      color: inherit;
      font-size: inherit;
      display: block;
      padding-top: 4px;
    }

    &-inactive {
      color: var(--color-gray-500);
    }

    &-primary {
      color: var(--color-white);
      background: var(--color-primary);

      &:active {
        background: var(--color-secondary-700);
      }
    }
  }

  &__search {
    width: 100%;
    height: 100%;
    padding-inline-start: 0 !important;
    padding-inline-end: 0 !important;

    --box-shadow: unset;
    --icon-color: var(--color-gray-200);
    --placeholder-color: var(--color-gray-500);
    --placeholder-opacity: 1;

    &-container {
      display: contents;
    }

    .searchbar-input-container,
    .searchbar-input {
      height: inherit;
    }

    .searchbar-input {
      width: calc(100% - $size-xs);
      font-size: $size-lg;
      padding-top: $size-xxs;
      margin-left: $size-xxs;
    }

    .searchbar-search-icon {
      top: 50%;
      transform: translateY(-50%);
      width: $size-xl;
      height: $size-xl;
    }

    .searchbar-clear-icon {
      width: $size-lg;
      color: var(--color-gray-700);
    }
  }

  &__title {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: flex-start;
    padding-left: $size-xxl;

    &-heading,
    &-info {
      display: block;
      padding-top: 4px;
    }

    &-heading {
      color: var(--color-gray-900);
      padding-right: $size-xxs;
      font-size: 2.125 * $size-base;
      font-weight: normal;
    }

    &-info {
      padding-left: calc(#{$size-xxs} + 1px);
      position: relative;
      color: var(--color-gray-600);
      font-size: 1.375 * $size-base;
      letter-spacing: 1.5px;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: calc(50% - 1px);
        transform: translateY(-50%);
        width: 2px;
        height: calc(100% + #{$size-xxs});
        background: var(--color-gray-600);
      }
    }
  }
}
